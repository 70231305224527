@import '@/theme/newVariables';

.alertHeader {
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  font-family: $font-az-cond;
  padding-right: 0 !important;
  padding-bottom: 8px !important;
  letter-spacing: normal;
}

.safariHeader {
  letter-spacing: -0.04em !important;
}

.alertBody {
  font-family: $font-az-helvetica;
  color: $az-grey-14;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  font-size: $font-size-14;
  line-height: $az-spacing-22;

  white-space: nowrap;
}
.links {
  text-decoration: underline;
  font-weight: 500;
  padding-left: 4px;

  a {
    color: $az-grey-14;
  }
}

.boldLink {
  -webkit-text-stroke: 0.25px;
}

.iconContainer {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  gap: 8px;
}

.signInAlertPopper {
  width: fit-content !important;
  opacity: 1 !important;
  z-index: 1450 !important;
  margin-right: -30px !important;
}

.signInPaper {
  width: fit-content !important;
  svg {
    height: 29.85px;
    width: 29.85px;
    fill: $az-grey-14;
  }

  button {
    margin-top: 8px;
  }
}

.tooltipArrow {
  // Note: !important is needed here to override the inline styles of popper.js
  height: 8px !important;
  width: 8px !important;
  padding-left: 15px !important;
}
